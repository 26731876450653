<template>
  <CustomInput
    v-if="type === 'text'"
    :value="value"
    :label="label"
    @input="(v) => change(v)"
  />
  <RichText
    v-else-if="type === 'textarea'"
    :value="value"
    :label="label"
    @input="(v) => change(v)"
  />
  <NumberInput
    v-else-if="type === 'number'"
    :value="Number(value) || 0"
    :label="label"
    @change="(v) => change(v)"
  />
  <InputFile
    v-else-if="type === 'media'"
    :value="value"
    :label="label"
    :multi="config.multi"
    :max-size="1024 * 10"
    @change="(v, _config) => change(v, _config)"
  />
  <MultiSelect
    v-else-if="type === 'relation'"
    :value="value"
    :label="label"
    :config="config"
    @change="(v) => change(v)"
  />
  <InputDate
    v-else-if="type === 'date'"
    :value="value"
    :label="label"
    :config="config"
    @change="(v) => change(v)"
  />
  <SwitchComp
    v-else-if="type === 'boolean'"
    :value="Boolean(value)"
    class="fc-switch"
    :label="label"
    :config="config"
    @change="(v) => change(v)"
  />
</template>

<script>
import CustomInput from "../ui/Input";
import RichText from "../ui/RichText";
import InputFile from "../ui/InputFile";
import NumberInput from "../ui/NumberInput";
import MultiSelect from "../ui/MultiSelect";
import InputDate from "../ui/InputDate";
import SwitchComp from "../ui/Switch";
import { namedListTypeField } from "@/const";

export default {
  name: "FormControl",
  components: {
    CustomInput,
    NumberInput,
    RichText,
    InputFile,
    InputDate,
    MultiSelect,
    SwitchComp
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number, Boolean, Array, File]
    },
    label: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    namedListTypeField () {
      return namedListTypeField
    },
  },
  methods: {
    change (v, _config = null) {
      this.$emit('change', v, _config)
    }
  }
}
</script>

<style scoped lang="less">
.fc-switch {
  margin-top: 20px;
  height: 36px;
  display: flex;
  align-items: center;
}
</style>
