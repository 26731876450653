<template>
  <ModalRCMS>
    <div>
      {{ params.title }}
    </div>
    <div>
      {{ params.message }}
    </div>
    <div>
      <CustomBtn
        v-for="(btn, index) in params.buttons"
        :key="index"
        :type="btn.type || 'default'"
        @click="() => click(btn)"
      >
        {{ btn.text }}
      </CustomBtn>
    </div>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";
import CustomBtn from "@/components/ui/Btn";

export default {
  name: "HelpModal",
  components: {
    ModalRCMS,
    CustomBtn
  },
  props: {
    params: Object
  },
  mounted() {
    console.log(this.params)
  },
  methods: {
    click (btn) {
      if (btn.click) {
        this.clickHandler(btn.click)
      }

      if (btn.afterClick) {
        this.clickHandler(btn.afterClick)
      }
    },
    clickHandler (click) {
      if (typeof click === 'string') {
        if (click === 'emitClose') {
          return this.$emit('close')
        }
      }

      return click()
    }
  }
}
</script>

<style scoped>

</style>
