<template>
  <div>
    <section class="p-16">
      <h2>
        Настройки
      </h2>
    </section>
    <section>
      <NodeForm mode="settings" />
    </section>
  </div>
</template>

<script>
import NodeForm from "@/components/forms/NodeForm";
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'AdminSettingsPage',
  components: {
    NodeForm
  },
  data() {
    return {
      entity: "",
      node: "",
      nodes: []
    };
  },
  computed: {
    ...mapGetters('entities', [
      'getEntities'
    ]),
    entityOptions () {
      return this.getEntities.map(entity => ({ name: entity.title, value: entity.hash }));
    },
    nodeOptions () {
      return this.nodes.map(node => ({ name: `${ JSON.stringify(Object.values(node.fields)) }`, value: node.hash }));
    }
  },
  mounted () {
    this.loadSettings()
  },
  methods: {
    ...mapActions('forms/node', [
      'loadSettings'
    ]),
  }
}
</script>

<style>
</style>
