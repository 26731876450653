<template>
  <ModalRCMS>
    <section class="p-16" style="width: 600px">
      <button @click="() => this.$emit('close')">Закрыть</button>
      <div>
        <div v-for="field in fields" :key="field.id" class="form__item">
          <CustomInput
            :value="form[field.hash]"
            :label="field.title"
            @input="(value, _config) => change({ field, value }, _config)"
          />
        </div>
      </div>
      <button @click="() => search()">Найти</button>
    </section>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";
import { requestNodes } from "@/services/api/requests";
import CustomInput from "@/components/ui/Input";

export default {
  name: "FilterModal",
  components: {
    ModalRCMS,
    CustomInput
  },
  props: {
    params: Object
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    fields () {
      return this.getEntityData && this.getEntityData.fields || {}
    },
    getEntityData() {
      return this.params.entity
    },
  },
  mounted () {
    if (this.$route.query && this.$route.query.filters) {
      this.form = JSON.parse(this.$route.query.filters)
    }
  },
  methods: {
    buildConfig (field) {
      return {
        endpointOptions: (params) => requestNodes({ hash: field.config.entityHash, ...params }),
        responseMapper: (response) => response.data.nodes.map((item) => ({ value: item.hash, label: item.fields[response.data.entity.config.mainField] }))
      }
    },
    change (v) {
      this.form = {
        ...this.form,
        [v.field.hash]: v.value
      }
    },
    search () {
      const form = { ...this.form }
      const keys = Object.keys(this.form)
      keys.forEach(key => {
        if (!form[key]) {
          delete form[key]
        }
      })
      this.$router.push({ query: { ...this.$route.query, filters: JSON.stringify(form) } })
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
