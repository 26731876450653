<template>
  <div class="pagination">
    <div class="pagination__buttons">
      <button
        v-for="(rangeNum, index) in currentRange"
        class="pagination__button"
        :class="{ 'pagination__button--active': rangeNum === value.page }"
        :key="index"
        @click="() => clickItem(rangeNum)"
      >
        {{ rangeNum }}
      </button>
    </div>
    <div class="pagination__text">
      Показывать по:
    </div>
    <select :value="value.limit" @change="(e) => changeLimit(e.target.value)">
      <option value="10">10</option>
      <option value="30">30</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

    <span class="pagination__text">Всего: {{ value.count }}</span>
  </div>
</template>

<script>
export default {
  name: 'PaginationComponent',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: 7
    }
  },
  computed: {
    currentRange () {
      const { page, limit, count } = this.value
      if (limit >= count) {
        return [1] // 1
      }

      const max = this.max
      const firstNumber = 1
      const lastNumber = Math.ceil(count / limit)

      if (lastNumber <= max) {
        return this.getRange(firstNumber, lastNumber) // 1, 2, 3, 4
      }
      const range = this.getRange(firstNumber, max)
      range[range.length - 1] = lastNumber

      if ((page - firstNumber) <= 3) {
        range[range.length - 2] = '...'
      } else if ((lastNumber - page) <= 3) {
        for (let i = 1; i < max - 2; i += 1) {
          range[range.length - i - 1] = lastNumber - i
        }
        range[1] = '...'
      } else {
        range[1] = '...'
        range[2] = page - 1
        range[3] = page
        range[4] = page + 1
        range[range.length - 2] = '...'
      }

      return range
    }
  },
  methods: {
    changeLimit (limit) {
      this.$emit('change', {
        ...this.value,
        page: 1,
        limit
      })
    },
    changePage (page) {
      this.$emit('change', {
        ...this.value,
        page
      })
    },
    clickItem (rangeNum) {
      if (rangeNum === '...') {
        return ''
      }
      this.changePage(rangeNum)
    },
    getRange (from, to) {
      return Array(to - from + 1)
        .fill(undefined)
        .map((v, i) => from + i)
    }
  }
}
</script>
<style scoped lang="less">
@cBaseLightBlue: #d94e67;

.pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: @ffOne;

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__button {
    font-family: @ffOne;
    @size: 28px;
    font-size: 13px;
    font-weight: 600;
    border: none;
    width: @size;
    height: @size;
    line-height: @size;
    text-align: center;
    color: #606266;
    background: #f4f4f5;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      color: @cBaseLightBlue;
    }

    &--active {
      color: #fff;
      background: @cBaseLightBlue;

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
