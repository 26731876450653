<template>
  <div>
    <section class="p-16">
      <div class="rcms-header">
        <div class="entity__nav">
          <RouterLink :to="{ name: $routeNames.ENTITY, params: { entity: getEntityData.code } }">
            <CircleBtn icon="upLeft" />
          </RouterLink>
        </div>

        <h2 class="rcms-header__title">
          Создать запись ({{ getEntityData.title || '-' }})
        </h2>

        <div v-if="getEntityData.config && getEntityData.config.multi" class="entity__nav">
          <SwitchComp v-model="multi" label="Мультизагрузка" />
        </div>
      </div>
    </section>

    <section>
      <NodeForm />
    </section>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import NodeForm from "@/components/forms/NodeForm";
import CircleBtn from "@/components/ui/CircleBtn";
import SwitchComp from "@/components/ui/Switch";

export default {
  name: 'CreateNodePage',
  components: {
    NodeForm,
    CircleBtn,
    SwitchComp
  },
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode'
    ]),
    ...mapGetters('forms/node', [
      'getFields',
      'getMultiMode',
    ]),
    multi: {
      get () {
        return this.getMultiMode
      },
      set (v) {
        this.setMultiMode(v)
      }
    },
    getEntityCode () {
      return this.$route.params.entity
    },
    title () {
      return 'Страницы'
    },
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity) || {}
    }
  },
  mounted () {
    this.setForm({
      fields: this.getEntityData.fields,
      form: {
        entity: this.$route.params.entity
      }
    })
  },
  destroyed () {
    this.multi = false
  },
  methods: {
    ...mapMutations('forms/node', [
      'setForm',
      'setMultiMode'
    ])
  }
}
</script>
