<template>
  <div
    class="switch-button__wrapper"
    :class="{
      'switch-button__wrapper--disabled': disabled
    }"
  >
    <div
      class="switch-button"
      :class="{
        'switch-button--active': value
      }"
      @click="() => toggle()"
    >
      <div class="switch-button__circle"/>
    </div>
    <div v-if="label" class="switch-button__label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchRCMS",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    toggle () {
      if (this.disabled) {
        return
      }
      this.$emit("change", !this.value)
    }
  }
}
</script>

<style scoped lang="less">
.switch-button {
  width: 38px;
  min-width: 38px;
  height: min-content;
  border-radius: 12px;
  transition: 0.2s;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: @cBaseTwo;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__label {
    font-size: 18px;
    line-height: 16px;
    padding-top: 1px;
    font-family: @ffOne;
  }

  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: @cBaseThree;
    transition: 0.2s;
    background-color: @cBaseThree;
  }

  &--active {

    .switch-button__circle {
      background: @cBaseFour;
      transform: translateX(18px);
    }
  }

  &--disabled {
    opacity: 0.5;

    .switch-button__circle {
      opacity: 0.5;
    }
  }

}
</style>
