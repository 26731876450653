<template>
  <div>
    <section v-if="getEntityData" class="p-16">
      <div class="d-flex w-100 justify-sb">
        <div class="entity__nav d-flex">
          <RouterLink :to="{ name: $routeNames.ENTITY, params: { entity: getEntityData.code } }">
            <CircleBtn icon="upLeft" />
          </RouterLink>

          <h2>
            Редактировать сущность
          </h2>
        </div>
      </div>
    </section>

    <section v-if="userHasRole(userRoles.ROLE_ADMIN)">
      <EntityForm mode="edit" />
    </section>
  </div>
</template>

<script>
import EntityForm from "@/components/forms/EntityForm";
import { mapGetters, mapMutations } from "vuex";
import CircleBtn from "@/components/ui/CircleBtn";
import { user } from "@/mixins/user";

export default {
  name: 'EditEntityPage',
  components: {
    EntityForm,
    CircleBtn
  },
  mixins: [user],
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode'
    ]),
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity)
    }
  },
  mounted () {
    this.setFormHandler()
  },
  methods: {
    ...mapMutations('forms/entity', [
      'setForm'
    ]),
    setFormHandler () {
      if (this.getEntityData) {
        this.setForm({
          ...this.getEntityData,
          fields: Object.values(this.getEntityData.fields)
        })
      }
    }
  },
  watch: {
    getEntityData: {
      handler () {
        this.setFormHandler()
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
