import { render, staticRenderFns } from "./PicturePreview.vue?vue&type=template&id=67b67f64&scoped=true"
import script from "./PicturePreview.vue?vue&type=script&lang=js"
export * from "./PicturePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b67f64",
  null
  
)

export default component.exports