<template>
  <div>
    <section class="p-16">
      <h2>
        Создать
      </h2>
    </section>
    <section class="p-16">
      <CustomInput v-model="form.username" label="Имя пользователя / Email" />
      <CustomInput v-model="form.password" label="Пароль" />
      <CustomBtn @click="() => saveHandler()">Сохранить</CustomBtn>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomBtn from "@/components/ui/Btn";
import CustomInput from "@/components/ui/Input";

export default {
  name: 'AdminSettingsPage',
  components: {
    CustomBtn,
    CustomInput,
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'createUser'
    ]),
    saveHandler () {
      this.createUser(this.form)
    }
  }
}
</script>

<style>
</style>
