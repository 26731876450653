<template>
  <div>
    <section class="p-16">
      Добро пожаловать!
    </section>
    <section v-if="userHasRole(userRoles.ROLE_ADMIN)" class="p-16">
      ADMIN

      <RouterLink :to="{ name: $routeNames.ADMIN_SETTING }">
        Настройки
      </RouterLink>
    </section>
  </div>
</template>

<script>
import { user } from "@/mixins/user";

export default {
  name: 'HomePage',
  mixins: [
    user
  ],
  data() {
    return {
      selectedOption: "",
    };
  },
  methods: {
    customLabel (option) {
      return (`${option.name} - ${option.value}`);
    }
  },
}
</script>

<style>
</style>
