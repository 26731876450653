<template>
  <div class="type" :class="'type--' + type">
    <IconRCMS :icon="icon" :color="colorIcon" size="mini"/>
  </div>
</template>

<script>
import IconRCMS from "./../ui/Icon.vue"
import { namedListColorMap } from "@/const"

export default {
  name: "BadgeIcon",
  components: {
    IconRCMS
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    colorIcon() {
      return namedListColorMap[this.type] || ''
    }
  }
}

</script>

<style scoped lang="less">
.type {
  border: @sizeBorderDefault solid #000000;
  border-radius: 4px;
  display: inline-flex;
  padding: 2px 8px;
  background-color: @cBaseTwo;
  box-sizing: border-box;

  &--green {
    background-color: #CEFFCD;
    border-color: #00AB44;
  }

  &--blue {
    background-color: #CDE2FF;
    border-color: #006DAB;
  }

  &--orange {
    background-color: #FFE5CD;
    border-color: #E48900;
  }

  &--brown {
    background-color: #c8b8b3;
    border-color: #795548;
  }

  &--violet {
    background-color: #e6daf2;
    border-color: #9b40ec;
  }

  &--aqua {
    background-color: #e2fafa;
    border-color: #70d6d6;
  }

  &--pink {
    background-color: #fce8ff;
    border-color: #f451bd;
  }

  &--red {
    background-color: #ffecec;
    border-color: #fb4747;
  }

  &--bluegrey {
    background-color: #dceef7;
    border-color: #486e82;
  }
}
</style>
