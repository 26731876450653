<template>
  <div>
    <MainMenu />
    <div v-loading="getLoaderContent || loading" class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: 'PageLayout',
  components: {
    MainMenu
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getLoaderContent'
    ])
  }
}
</script>

<style scoped lang="less">
.content {
  margin-left: 300px;
  padding: 20px 34px;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}
</style>
