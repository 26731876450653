<template>
  <ModalRCMS>
    <section class="p-16" style="width: 600px">
      <h2>
        Конфигурация поля
      </h2>

      <SelectRCMS
        v-model="form.entityHash"
        :options="getOptionsEntities"
        :disabled="false"
        no-data-message="Пусто"
        label="Выберите сущность"
      />

      <div>
        <NumberInput
          v-model="form.count"
          label="Количество"
          :min="1"
        />
        <SwitchRCMS
          v-model="form.unlimit"
          label="Неограничено"
        />
      </div>

      <CustomBtn @click="() => save()">Сохранить</CustomBtn>
    </section>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";
import SelectRCMS from "@/components/ui/Select";
import NumberInput from "@/components/ui/NumberInput";
import SwitchRCMS from "@/components/ui/Switch";
import CustomBtn from "@/components/ui/Btn";
import { mapGetters, mapMutations } from "vuex";
import { requestEntities } from "@/services/api/requests";

export default {
  name: "RelationFieldConfig",
  components: {
    ModalRCMS,
    SelectRCMS,
    NumberInput,
    SwitchRCMS,
    CustomBtn
  },
  props: {
    params: Object
  },
  data () {
    return {
      entities: [],
      form: {
        entityHash: '',
        count: 1,
        unlimit: false,
      }
    }
  },
  computed: {
    ...mapGetters('forms/entity', [
      'getField',
    ]),
    getOptionsEntities () {
      return this.entities.map(entity => ({ name: entity.title, value: entity.hash }))
    }
  },
  mounted() {
    this.form.entityHash = this.params.field.config.entityHash
    this.form.count = this.params.field.config.count
    this.form.unlimit = this.params.field.config.unlimit

    requestEntities()
      .then((response) => {
        this.entities = response.data
      })
  },
  methods: {
    ...mapMutations('forms/entity', [
      'setConfigFieldCustomField',
    ]),
    save () {
      this.setConfigFieldCustomField({
        config: {
          ...this.params.field.config,
          ...this.form
        },
        index: this.params.index
      })
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
