<template>
  <div class="check-list">
    <div v-for="option in options" :key="option.value" class="check-list__row">
      <div>
        <CheckboxRCMS :value="value.includes(option.value)" @change="(v) => changeCB(v, option.value)" />
      </div>
      <div>{{ option.label }}</div>
    </div>
  </div>
</template>

<script>
import CheckboxRCMS from "@/components/ui/Checkbox";

export default {
  name: "CheckList",
  components: {
    CheckboxRCMS
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: Array,
    value: Array,
    limit: Number
  },
  methods: {
    changeCB (v, key) {
      const newValue = new Set(this.value)
      if (v) {
        newValue.add(key)
      } else {
        newValue.delete(key)
      }
      this.$emit('change', Array.from(newValue))
    }
  }
}
</script>

<style lang="less" scoped>
.check-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  &__row {
    display: flex;
    gap: 10px;
  }
}
</style>
