<template>
  <div @click="() => $emit('click')" class="circle-btn" :class="'circle-btn--' + type">
    <IconRCMS :icon="icon" :color="colorCircleBtn" :type="type"/>
  </div>
</template>

<script>
import IconRCMS from './Icon.vue'

export default {
  name: 'CircleBtn',
  components: {
    IconRCMS
  },
  props: {
    icon: {
      default: '',
      type: String
    },
    type: {
      default: 'default',
      type: String
    }
  },
  computed: {
    colorCircleBtn () {
      switch (this.type) {
        case 'error':
          return '#ffffff'
      }
      return '#D94E67'
    }
  }
}

</script>

<style lang="less" scoped>
.circle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: @cBaseTwo;
  color: @cBaseFour;
  border-radius: 100%;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #ffc1cc;
  }

  &--error {
    background-color: @cBaseFour;
    color: #ffffff;
  }
}
</style>
