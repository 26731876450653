import { mapGetters } from "vuex";
import { USER_ROLE } from "@/services/user";

export const user = {
  computed: {
    ...mapGetters('users', [
      'userHasRole'
    ]),
    userRoles () {
      return USER_ROLE
    }
  }
}
