<template>
  <div class="icon-select">
    <div class="icon-select__field">
      <div>Иконка:</div>
      <button class="icon-select__button" @click="() => openIconsModal()">
        <span v-if="value">
          <IconRCMS :icon="value" size="mini" />
        </span>
        <span v-else>Выбрать иконку</span>
      </button>
    </div>
  </div>
</template>

<script>
import IconRCMS from "@/components/ui/Icon";
import { iconsModal } from "@/mixins/modals";

export default {
  name: "IconSelect",
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    IconRCMS,
  },
  props: {
    value: {
      type: String,
      default: ""
    },
  },
  mixins: [
    iconsModal
  ],
  methods: {
    openIconsModal() {
      this.openIconSelectModal({
        value: this.value,
        onSelect: (v) => this.$emit('change', v)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.icon-select {
  width: auto;
  display: flex;
  gap: 20px;

  &__button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    background-color: #e3e5e8;
    border: none;
    color: black;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #a1a1a2;
    }

    span {
      display: inline-block;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
</style>
