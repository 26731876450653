<template>
  <div v-if="user">
    <section class="p-16">
      <h2>
        Пользователь {{ user.username }}
      </h2>
    </section>
    <section class="p-16">
      <CustomInput v-model="password" label="Новый пароль" />

      <SelectRCMS
        v-model="role"
        :options="selectableRoles"
        label="Выберите роль"
      />

      <MultiSelect
        :value="entities"
        :config="{
          endpointOptions: fetchEntityOptions,
          responseMapper: mapEntityOptions
        }"
        placeholder="Выберите сущности"
        label=""
        @change="(v) => handleMultiSelectChange(v)"
      />

      <CustomBtn @click="() => saveHandler()">Сохранить</CustomBtn>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomBtn from "@/components/ui/Btn";
import CustomInput from "@/components/ui/Input";
import MultiSelect from "@/components/ui/MultiSelect";
import SelectRCMS from "@/components/ui/Select";
import { selectableRoles } from "@/services/user";

export default {
  name: 'EditUserPage',
  components: {
    CustomBtn,
    CustomInput,
    MultiSelect,
    SelectRCMS,
  },
  data () {
    return {
      password: '',
      role: '',
      entities: []
    }
  },
  computed: {
    ...mapGetters('users', [
      'getUserById'
    ]),
    ...mapGetters("entities", [
      "getEntities"
    ]),
    user () {
      return this.getUserById(this.$route.params.id)
    },
    selectableRoles () {
      return selectableRoles
    },
    entityOptions() {
      if (!this.getEntities || !this.getEntities.length) {
        return [];
      }
      return this.getEntities
        .filter((entity) => entity.code && entity.title)
        .map((entity) => ({
          value: entity.code,
          label: entity.title,
        }));
    },
  },
  mounted () {
    this.loadUser({ id: this.$route.params.id })
  },
  methods: {
    ...mapActions('users', [
      'loadUser',
      'updateUser',
    ]),
    fetchEntityOptions(params) {
      const search = params.search.toLowerCase();
      const filteredEntities = this.entityOptions.filter((entity) =>
        entity.label.toLowerCase().includes(search)
      );
      return Promise.resolve(filteredEntities);
    },
    mapEntityOptions(entities) {
      return entities;
    },
    handleMultiSelectChange(value) {
      this.entities = value;
    },
    saveHandler () {
      this.updateUser({
        id: this.$route.params.id,
        password: this.password
      })
    }
  }
}
</script>
