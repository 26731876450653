<template>
  <ModalRCMS>
    <section class="p-16" style="width: 600px">
      <h2>
        Загрузить файлы
      </h2>

      <div>
        <div
          class="dropzone"
          @dragover.prevent="(event) => handleDragOver(event)"
          @drop.prevent="(event) => handleDrop(event)"
        >
          Перетащите файлы сюда
        </div>
        или выбрать вручную
        <input
          type="file"
          multiple
          ref="fileInput"
          placeholder="Выбрать файлы"
          @change="(event) => handleFiles(event)"
        />

        <div>
          <div>
            Режим загрузки: {{ isSingleMode ? 'По очереди' : 'Все сразу' }}
            <button @click="() => toggleUploadMode()">Переключить</button>
          </div>
          <div>
            Автоматическая загрузка: {{ autoUploadEnabled ? 'Включено' : 'Выключено' }}
            <button @click="() => toggleAutoUpload()">Переключить</button>
          </div>
          <button @click="() => uploadFiles()">Загрузить ({{ isSingleMode ? 'По очереди' : 'Все сразу' }})</button>
        </div>

        <div class="file-list">
          <div v-for="(file, index) in files" :key="index" class="file-item">
            <span class="file-name">{{ file.name }}</span> -
            <span v-if="file.status === FILE_STATUS.WAITING" class="file__delete" @click="() => deleteFile(index)">Удалить</span>
            <span v-if="file.status === FILE_STATUS.DONE" class="file__delete" @click="() => deleteFile(index)">Скрыть</span>
            <span class="file-status" :class="file.status.toLowerCase()">{{ FILE_STATUS_LABEL[file.status] }}</span>
          </div>
        </div>

        <div>Всего файлов: {{ files.length }}</div>
        <div>Файлов в очереди: {{ filesByStatus[FILE_STATUS.WAITING].length }}</div>
        <div>Загружено файлов: {{ filesByStatus[FILE_STATUS.DONE].length }}</div>
      </div>

      <div style="margin-top: 16px">
        <CustomBtn @click="() => save()">Готово</CustomBtn>
      </div>
    </section>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "../parts/Modal"
import CustomBtn from "@/components/ui/Btn"

const FILE_STATUS = {
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  DONE: 'DONE',
}

export default {
  name: "MultiFileUpModal",
  components: {
    ModalRCMS,
    CustomBtn
  },
  props: {
    params: Object
  },
  data () {
    return {
      files: [],
      activeUploadMode: 'single', // 'single', 'batch'
      autoUploadEnabled: false,

      currentUploadingFiles: [],
    }
  },
  computed: {
    isSingleMode () {
      return this.activeUploadMode === 'single'
    },
    isBatchMode () {
      return this.activeUploadMode === 'batch'
    },
    FILE_STATUS () {
      return FILE_STATUS
    },
    FILE_STATUS_LABEL () {
      return {
        [FILE_STATUS.DONE]: 'Загружен',
        [FILE_STATUS.WAITING]: 'В очереди',
        [FILE_STATUS.UPLOADING]: 'Загружается',
        [FILE_STATUS.ERROR]: 'Ошибка',
      }
    },
    filesByStatus () {
      const files = {
        ...FILE_STATUS
      }
      Object.keys(files).forEach(key => {
        files[key] = []
      })
      this.files.forEach((file) => {
        files[file.status].push(file)
      })
      return files
    }
  },
  methods: {
    toggleUploadMode () {
      this.activeUploadMode = this.isSingleMode ? 'batch' : 'single'
    },
    toggleAutoUpload () {
      this.autoUploadEnabled = !this.autoUploadEnabled
    },
    addFiles (selectedFiles) {
      Array.from(selectedFiles).forEach(file => {
        this.files.push({
          data: file,
          name: file.name,
          status: FILE_STATUS.WAITING,
        })
      })
      if (this.autoUploadEnabled) {
        if (this.isSingleMode) {
          this.uploadNextFile()
        } else {
          this.uploadAllFiles()
        }
      }
    },
    handleDragOver (event) {
      event.preventDefault()
    },
    save () {
      this.$emit('close')
    },
    handleFiles (event) {
      const selectedFiles = event.target.files
      this.addFiles(selectedFiles)
    },
    handleDrop (event) {
      const selectedFiles = event.dataTransfer.files
      this.addFiles(selectedFiles)
    },
    uploadFiles () {
      if (this.isSingleMode) {
        this.uploadNextFile()
      } else {
        this.uploadAllFiles()
      }
    },
    uploadNextFile () {
      const nextFile = this.files.find(file => file.status === FILE_STATUS.WAITING)
      if (nextFile) {
        this.uploadFile(nextFile)
      }
    },
    uploadAllFiles () {
      this.files.forEach(file => {
        if (file.status === FILE_STATUS.WAITING) {
          this.uploadFile(file)
        }
      })
    },
    uploadFile (file) {
      file.status = FILE_STATUS.UPLOADING
      this.params.loadFile(file.data)
        .then(() => {
          file.status = FILE_STATUS.DONE
          this.uploadNextFile()
        })
    },
    deleteFile (index) {
      this.files.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="less">
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.file {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
  }

  &-name {
    flex-grow: 1;
  }

  &-status {
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.waiting {
      background-color: #ffecb3;
    }

    &.uploading {
      background-color: #ffcc80;
    }

    &.done {
      background-color: #c8e6c9;
    }
  }

  &__delete {
    background: #fcc2c2;
    border-radius: 3px;
    cursor: pointer;
    padding: 2px;
  }
}

.file-list {
  max-height: 50vh;
  overflow-y: auto;
}
</style>

