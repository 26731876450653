<template>
  <ModalRCMS>
    <div class="icon-picker-modal">
      <div class="icon-picker-modal__header">
        <h3 class="icon-picker-modal__title">Выберите иконку</h3>
        <button
          class="icon-picker-modal__close-btn"
          @click="() => $emit('close')"
        >
          Закрыть
        </button>
      </div>

      <div class="icon-picker-modal__grid">
        <div
          v-for="icon in icons"
          :key="icon"
          class="icon-picker-modal__item"
          @click="() => selectIcon(icon)"
        >
          <IconRCMS :icon="icon" size="mini" />
        </div>
      </div>
    </div>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";
import IconRCMS, { icons } from "@/components/ui/Icon";

export default {
  name: "IconsSelectModal",
  components: {
    ModalRCMS,
    IconRCMS,
  },
  props: {
    params: Object
  },
  computed: {
    icons () {
      return Object.keys(icons)
    }
  },
  methods: {
    selectIcon(iconName) {
      this.params.onSelect(iconName);
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="less">
.icon-picker-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
    gap: 30px;
  }

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  &__close-btn {
    background: none;
    border: none;
    font-size: 16px;
    color: #555;
    cursor: pointer;
    padding: 4px 8px;
    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 16px;
    justify-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__name {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
    color: #333;
  }
}
</style>
