export function waitThrottle (func, ms) {
  let timeout

  function wrapper (...args) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      func.apply(this, args)
    }, ms)
  }

  return wrapper
}


const ruAplh = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
const ruEnAplh = 'abvgdeegziiklmnoprstufhccssiiieya'

const enAplh = 'abcdefghijklmnopqrstuvwxyz'


export function convertToSlug (v) {
  let slug = ''
  for (const i in v) {
    const symbol = v[i].toLowerCase()
    const index = ruAplh.indexOf(symbol)
    const indexEn = enAplh.indexOf(symbol)
    if (index >= 0) {
      slug += ruEnAplh[index]
    } else if (indexEn >= 0) {
      slug += enAplh[indexEn]
    } else {
      slug += '-'
    }
  }
  return slug
}
