<template>
  <div>
    <section class="p-16">
      <h2>
        Создать сущность
      </h2>
    </section>

    <section>
      <EntityForm @save="(e) => gotoList(e)" />
    </section>
  </div>
</template>

<script>
import EntityForm from "@/components/forms/EntityForm";

export default {
  name: 'CreateEntityPage',
  components: {
    EntityForm
  },
  computed: {
    title () {
      return 'Страницы'
    }
  },
  methods: {
    gotoList (entity) {
      console.log(entity)
      this.$router.push({ name: this.$routeNames.ENTITY, params: { entity: entity.code } })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
