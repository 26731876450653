<template>
  <div class="theme-switcher" :class="themeState">
    <div class="switch" @click="() => toggleTheme()">
      <div class="circle">
        <IconRCMS v-if="themeState === 'dark'" icon="moon" />
        <IconRCMS v-else-if="themeState === 'light'" icon="sun" color="white" />
        <IconRCMS v-else icon="systemTheme"/>
      </div>
    </div>
  </div>
</template>

<script>
import IconRCMS from "./../ui/Icon.vue";

export default {
  name: "ThemeSwitcher",
  components: {
    IconRCMS,
  },
  data() {
    return {
      previousTheme: window.matchMedia('(prefers-color-scheme: dark)').matches == "light" ? "dark" : "light",
    };
  },
  computed: {
    themeState() {
      return this.$store.state.theme || "system";
    },
  },
  mounted() {
    if (this.themeState === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', this.onSystemThemeChange);
      } else {
        mediaQuery.addListener(this.onSystemThemeChange);
      }
      this.applyTheme(mediaQuery.matches ? 'dark' : 'light');
    } else {
      this.applyTheme(this.themeState);
    }
  },
  methods: {
    toggleTheme() {
      let nextTheme;
      if (this.themeState === "light") {
        nextTheme = "system";
      } else if (this.themeState === "system") {
        nextTheme = this.previousTheme === "light" ? "dark" : "light";
      } else if (this.themeState === "dark") {
        nextTheme = "system";
      }
      if (nextTheme === "system") {
        this.previousTheme = this.themeState;
      }
      this.$store.commit("setTheme", nextTheme);
    },
    applyTheme(theme) {
      const root = document.body;
      if (theme === "dark") {
        root.setAttribute("data-theme", "dark");
      } else if (theme === "light") {
        root.setAttribute("data-theme", "light");
      } else {
        root.removeAttribute("data-theme");
      }
    },
    onSystemThemeChange() {
      this.$store.commit("setTheme", "system");
    },
  }
};
</script>

<style lang="less" scoped>
@transition-duration: 0.3s;

.theme-switcher {
  &.dark {
    .switch {
      background-color: #313131;
    }
    .circle {
      background-color: #666;
    }
  }

  &.light {
    .switch {
      background-color: #d3d3d3;
    }
    .circle {
      background-color: #666;
    }
  }

  &.system {
    .switch {
      background-color: #a3a3a3;
    }
    .circle {
      background-color: #666;
    }
  }
}

.switch {
  width: 120px;
  height: 30px;
  border-radius: 90px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color @transition-duration ease;
}

.circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all @transition-duration ease;

  &:hover {
    transform: scale(1.02);
  }
}

.theme-switcher.dark .circle {
  left: 66%;
}

.theme-switcher.light .circle {
  left: 0%;
}

.theme-switcher.system .circle {
  left: 34%;
}
</style>
