import entity from './entity'
import node from './node'

export default {
  namespaced: true,
  modules: {
    entity,
    node
  }
}
