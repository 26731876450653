import users from './users'
import entities from './entities'
import nodes from './nodes'
import forms from './forms'
import modals from './modals'

export default {
  namespaced: true,
  state: {
    loaders: {
      content: false
    },
    theme: localStorage.getItem('theme') || "system",
  },
  getters: {
    getLoaderContent: (state) => state.loaders.content,
    getTheme: (state) => state.theme,
  },
  mutations: {
    setLoader: (state, payload) => {
      state.loaders[payload.loader] = payload.status
    },
    setTheme: (state, theme) => {
      state.theme = theme;
      const effectiveTheme =
        theme === 'system'
          ? window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light'
          : theme;
      document.body.setAttribute('data-theme', effectiveTheme);
      localStorage.setItem('theme', theme);
    },
  },
  modules: {
    users,
    entities,
    nodes,
    forms,
    modals
  }
}
