import axios from "axios";
const listRoutes = {
    admin: {
        get: '/api/admin/customEntity/getList',
        entity: '/api/admin/customEntity/getEntity',
        createEntity: '/api/admin/customEntity/createEntity',
        editEntity: '/api/admin/customEntity/editEntity',
        removeEntity: '/api/admin/customEntity/removeEntity',
        node: '/api/admin/customNode/getNode',
        nodes: '/api/admin/customNode/getNodes',
        createNode: '/api/admin/customNode/createNode',
        editNode: '/api/admin/customNode/editNode',
        removeNode: '/api/admin/customNode/removeNode',
        removeNodes: '/api/admin/customNode/removeNodes',
        getSettings: '/api/admin/settings/getSettings',
        saveSettings: '/api/admin/settings/saveSettings',
        getConfigSettings: '/api/admin/settings/getConfigSettings',
        saveConfigSettings: '/api/admin/settings/saveConfigSettings',
        getUsers: '/api/admin/users/getUsers',
        getUser: '/api/admin/users/getUser',
        updateUser: '/api/admin/users/updateUser',
        createUser: '/api/admin/users/createUser',
    },
    auth: {
        login: '/api/auth/login',
        check: '/api/auth/check'
    }
};
export var REQUEST_METHOD;
(function (REQUEST_METHOD) {
    REQUEST_METHOD["GET"] = "GET";
    REQUEST_METHOD["POST"] = "POST";
    REQUEST_METHOD["PUT"] = "PUT";
    REQUEST_METHOD["DELETE"] = "DELETE";
})(REQUEST_METHOD || (REQUEST_METHOD = {}));
const DOMAIN = process.env.DOMAIN_SERVER_ENV;
export class APIProvider {
    DOMAIN = DOMAIN;
    GET = REQUEST_METHOD.GET;
    POST = REQUEST_METHOD.POST;
    PUT = REQUEST_METHOD.PUT;
    DELETE = REQUEST_METHOD.DELETE;
    method = REQUEST_METHOD.GET;
    headers = {};
    pathRequest = '';
    formData = null;
    constructor() {
        this.setAutoParams();
        return this;
    }
    setAutoAuthHeaders = () => {
        const user_token = localStorage.getItem("userToken");
        const user_name = localStorage.getItem("userUsername");
        if (user_token &&
            user_token !== "null" &&
            user_token !== "undefined" &&
            user_token.length > 31) {
            this.setHeader({
                "Authorization": `${user_token} ${user_name}`,
                "X-AUTH-TOKEN": user_token,
                "X-AUTH-USERNAME": user_name
            });
        }
        return this;
    };
    cleanHeaders = () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userUsername");
        this.headers = {};
    };
    setAutoParams = () => {
        this.method = REQUEST_METHOD.GET;
        this.headers = {};
        this.pathRequest = '';
        this.formData = null;
        this.setAutoAuthHeaders();
    };
    setup = (method, lr) => {
        this.method = method;
        this.setPath(lr(listRoutes));
        return this;
    };
    setHeader = (headers) => {
        this.headers = {
            ...headers,
        };
        return this;
    };
    addHeader = (headers) => {
        this.headers = {
            ...this.headers,
            ...headers,
        };
        return this;
    };
    setPath = (path) => {
        this.pathRequest = `${this.DOMAIN}${path}`;
        return this;
    };
    setData = (formObject) => {
        this.addHeader({
            "Content-Type": "application/x-www-form-urlencoded"
        });
        this.formData = formObject;
        return this;
    };
    setFormData = (formObject) => {
        this.addHeader({
            "Content-Type": "multipart/form-data",
        });
        this.setFormDataForRequest(formObject);
        return this;
    };
    setFormDataForRequest = (formObject) => {
        const data = new FormData();
        const names = Object.keys(formObject);
        names.forEach(key => {
            if (key === "files") {
                const files = formObject[key];
                const fileNames = Object.keys(files);
                fileNames.forEach(nameFile => data.set(nameFile, files[nameFile]));
                return;
            }
            const value = typeof formObject[key] === "string" ? formObject[key] : JSON.stringify(formObject[key]);
            data.set(key, value);
        });
        this.formData = data;
    };
    request = (_data) => new Promise((resolve, reject) => {
        let data = _data;
        if (this.formData && !_data) {
            data = this.formData;
        }
        return ((this.method === REQUEST_METHOD.GET) ?
            axios.get(this.pathRequest, {
                headers: this.headers,
                params: data,
            }) :
            axios({
                url: this.pathRequest,
                headers: this.headers,
                method: this.method,
                data,
            }))
            .then((response) => {
            resolve(response);
        })
            .catch((error) => {
            reject(error);
        });
    });
}
