<template>
  <div class="auth-page__wrapper">
    <section class="auth-page__panel">
      <RouterLink :to="{ name: $routeNames.HOME}">
        <div class="auth-page__cms-name">
            RCMS
        </div>
      </RouterLink>
      <div class="rcms-divider-h" />
      <CustomInput v-model="form.username" label="Имя пользователя" />
      <CustomInput v-model="form.password" label="Пароль" type="password" />
      <div class="auth-page__btn-wrapper">
        <CustomBtn @click="() => log()">Войти</CustomBtn>
      </div>
    </section>
</div>
</template>

<script>
import CustomInput from "./../ui/Input.vue"
import CustomBtn from "./../ui/Btn.vue"
import { mapActions } from 'vuex';

export default {
  name: 'AuthPage',
  components: {
    CustomInput,
    CustomBtn
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'logIn'
    ]),
    log () {
      this.logIn(this.form)
    }
  }
}
</script>

<style lang="less" scoped>

.auth-page {

  &__panel {
    width: 360px;
    box-sizing: border-box;
    padding: 22px 26px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  &__cms-name {
    color: @cBaseFour;
    font-size: 44px;
    font-weight: 700;
    text-align: center;
    font-family: @ffOne;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: end;
    padding-top: 16px;
  }
}
</style>
