import Vue from 'vue';
import App from '@/components/App.vue';
import router from './router';
import Vuex from 'vuex';
import storeRoot from './store';
import { sync } from "vuex-router-sync";
import RouterBeforeEach from "./router/RouterBeforeEach";
import RoutesPlugin from '@/plugins/routes';

Vue.use(Vuex);
Vue.config.productionTip = false;


const store = new Vuex.Store(storeRoot);
sync(store, router);
router.beforeEach(RouterBeforeEach(store));

Vue.use(RoutesPlugin);

const RCMS_LOADING = 'rcms-loading'
Vue.directive('loading', {
  bind: function (el, binding) {
    if (binding.value) {
      el.classList.add(RCMS_LOADING)
    } else {
      el.classList.remove(RCMS_LOADING)
    }
  },
  update: (el, binding) => {
    if (binding.value) {
      el.classList.add(RCMS_LOADING)
    } else {
      el.classList.remove(RCMS_LOADING)
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
