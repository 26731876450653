<template>
  <div>
    <RouterView />
    <ModalContainer />
  </div>
</template>

<script>
import ModalContainer from "@/components/parts/ModalContainer";

export default {
  components: {
    ModalContainer
  }
}
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');

body {
  @current-theme: light;
  --cBase1: @cBaseOne;
  --cBase2: @cBaseTwo;
  --cBase3: @cBaseThree;
  --cBase4: @cBaseFour;
  --cBase6: @cBaseSix;

  margin: 0;
  padding: 0;
  background-color: var(--cBase2);
  color: var(--cBase3);

  &[data-theme="light"] {
    @current-theme: light;
    --cBase1: @cBaseOne;
    --cBase2: @cBaseTwo;
    --cBase3: @cBaseThree;
    --cBase4: @cBaseFour;
    --cBase6: @cBaseSix;
  }

  &[data-theme="dark"] {
    @current-theme: dark;
    --cBase1: @cBaseOneInversion;
    --cBase2: @cBaseTwoInversion;
    --cBase3: @cBaseThreeInversion;
    --cBase4: @cBaseFour;
    --cBase6: @cBaseSixInversion;
  }
}

a {
  text-decoration: none;
}

section {
  background-color: var(--cBase1);
  margin-bottom: 20px;
  border-radius: 2px;
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5 {
  font-family: @ffOne;
  color: var(--cBase3);
  margin: 0;
}

h2 {
  font-size: 32px;
}

.p-16 {
  padding: 16px;
}

.d-flex {
  display: flex;
}

.a-items {
  align-items: center;
}

.justify-sb {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.text-limit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rcms {

  &-divider {

    &-h {
      width: 100%;
      height: @sizeBorderDefault;
      background-color: @cBaseTwo;
    }

    &-v {

    }
  }

  &-loading {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(100, 100, 100, 0.5);
      cursor: wait;
    }
  }
}

.rcms-table {
  overflow: auto;

  &__header {
    display: grid;
    grid-gap: 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: @ffOne;
    padding-bottom: 2px;

    &__column {
      padding: 0 6px;
      box-sizing: border-box;
      color: var(--cBase6);

      &--default {
        width: 48px;
        text-align: center;
      }

      &--select {
        width: 48px;
      }
    }
  }

  &__rows {
    height: calc(100vh - 400px);
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
  }

  &__row {
    display: grid;
    grid-gap: 10px;
    font-family: @ffOne;
    font-size: 16px;
    transition: 0.2s;
    width: max-content;
    min-width: 100%;
    border-radius: 24px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--cBase2);

      & .rcms-table__row__nav {
        opacity: 1;
      }
    }

    &__nav {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      gap: 6px;
      padding: 8px;
      opacity: 0;
      transition: 0.2s;
    }
  }

  &__column {
    display: flex;
    align-items: center;
    padding: 0 6px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--cBase6);

    &--default {
      width: 48px;
      justify-content: center;
    }

    &--select {
      width: 48px;
    }
  }
}


.rcms-header {
  display: flex;
  align-items: center;
  gap: 10px;

  &__title {
    flex: 1;
  }
}
</style>
