<template>
  <ModalRCMS>
    <section class="p-16" style="width: 600px">
      <h2>
        Выбрать элементы
      </h2>

      <div style="margin-bottom: 16px">
        <InputRCMS
          v-model="search"
          label="Поиск"
        />
      </div>

      <CheckList
        v-model="selected"
        :options="options"
      />

      <div style="margin-top: 16px">
        <CustomBtn @click="() => save()">Сохранить</CustomBtn>
      </div>
    </section>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "../parts/Modal";
import CheckList from "../ui/CheckList";
import InputRCMS from "../ui/Input";
import CustomBtn from "@/components/ui/Btn";
import { waitThrottle } from "@/services/common";

export default {
  name: "MultiSelectModal",
  components: {
    ModalRCMS,
    CheckList,
    CustomBtn,
    InputRCMS
  },
  props: {
    params: Object
  },
  data () {
    return {
      selected: [],
      options: [],
      search: '',
      loadMethod: null
    }
  },
  watch: {
    search: {
      handler () {
        this.loadMethod()
      }
    }
  },
  mounted() {
    if (this.params.value) {
      this.selected = this.params.value
    }
    this.loadMethod = waitThrottle(this.loadOptions, 1000)
    this.loadMethod()
  },
  methods: {
    save () {
      if (this.params.changeCallback) {
        this.params.changeCallback(this.selected)
      }
      this.$emit('close')
    },
    loadOptions () {
      if (this.params.config && this.params.config.endpointOptions) {
        this.params.config.endpointOptions({
          search: this.search
        })
          .then(response => {
            this.options = this.params.config.responseMapper(response)
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
