<template>
  <div
    class="select"
    :tabindex="tabindex"
    :class="{
      'select--disabled': disabled,
    }"
    @focus="() => selectFocused()"
    @blur="() => selectFocusOut()"
  >
    <div
      v-if="label"
      class="select__label"
      :class="[ labelUp ? 'active' : '' ]"
      @click="() => (!labelUp && selectButtonClick())"
    >
      {{ label }}
    </div>
    <div
      class="select__button"
      :class="buttonClass"
      @mouseover="() => setTextHoverIfNotOpen()"
      @mouseleave="() => unHoverText()"
      @click="() => selectButtonClick()"
    >
      {{ buttonText }}
      <div class="select__button__icon">
        <IconRCMS icon="angle" :color="isOpen ? '#022E40' : '#C2C2C2'"/>
      </div>
    </div>
    <transition name="select__slide-fade">
      <div class="select__dropdown" v-show="isOpen">
        <ul class="select__options">
          <div class="select__no-data" v-if="!areOptionsExist">
            {{ noDataMessage }}
          </div>
          <li
            class="select__option"
            v-for="(option, index) in options"
            :key="index"
            :class="{
              'select__option--selected': isOptionSelected(option),
              'select__option--highlighted': isOptionHighlighted(option),
            }"
            @mouseover="() => setHighlightedOption(option)"
            @click="() => onOptionClick(option.value)"
          >
            {{ option.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import IconRCMS from "@/components/ui/Icon.vue"

export default {
  name: "SelectRCMS",
  components: {
    IconRCMS
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: String,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Выберите",
    },
    tabindex: {
      type: Number,
      default: 0,
      validator: (value) => (value >= 0),
    },
    noDataMessage: {
      type: String,
      default: "No Data",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  created () {
    this.initDefaultHighlightedOption();
    this.initDefaultSelectedOptionForInnerSelect();
  },
  data () {
    return {
      innerSelected: null,
      highlightedOption: null,
      isOpen: false,
      isTextHover: false,
      isFocused: false,
      forceLabel: false
    };
  },
  computed: {
    buttonText () {
      return this.value ? this.getOptionValue.name : '';
    },
    getOptionValue () {
      return this.value && this.options.find(item => item.value === this.value) || {}
    },
    buttonClass () {
      return {
        "select__button--focused": this.isFocused,
        "select__button--opened": this.isOpen,
        "select__button--highlighted": this.isTextHover,
      };
    },
    areOptionsExist () {
      return this.options.length;
    },
    labelUp: function () {
      return (this.value && this.value.length > 0) || this.forceLabel;
    }
  },
  methods: {
    initDefaultHighlightedOption () {
      this.highlightedOption = this.selected;
    },
    initDefaultSelectedOptionForInnerSelect () {
      this.innerSelected = this.selected;
    },
    isOptionSelected (option) {
      return option.value === this.value;
    },
    isOptionHighlighted (option) {
      return option.value === this.highlightedOption;
    },
    selectFocused () {
      this.isFocused = true;
    },
    selectFocusOut () {
      this.hideSelect();
      this.isFocused = false;
    },
    setTextHoverIfNotOpen () {
      if (!this.isOpen) {
        this.isTextHover = true;
      }
    },
    unHoverText () {
      this.isTextHover = false;
    },
    setHighlightedOption (option) {
      this.highlightedOption = option.value;
    },
    selectButtonClick () {
      if (this.isOpen) {
        this.hideSelect();
      } else {
        this.openSelect();
      }
    },
    openSelect () {
      this.isOpen = true;
      this.$emit("visible-change", true);
    },
    hideSelect () {
      this.isOpen = false;
      this.$emit("visible-change", false);
    },
    onOptionClick (value) {
      this.$emit("change", value);
      if (this.closeOnSelect) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.select {
  position: relative;
  width: 100%;
  outline: none;
  user-select: none;
  padding-top: 16px;

  &__label {
    position: absolute;
    padding: 0 14px;
    font-family: @ffOne;
    font-size: 16px;
    color: #C2C2C2;
    transition: 0.2s ease-in-out;
    top: 24px;
    z-index: 1;
    user-select: text;
    cursor: pointer;

    &.active {
      font-size: 14px;
      top: -6px;
      color: @cBaseThree;
      cursor: text;
    }
  }

  &--disabled {
    pointer-events: none;
    filter: brightness(0.7);
  }

  &__button {
    padding: 0 14px;
    height: 36px;
    font-size: 16px;
    line-height: 36px;
    background-color: #feffff;
    border: @sizeBorderDefault solid @cBaseTwo;
    border-radius: 4px;
    cursor: pointer;
    font-family: @ffOne;
    position: relative;
    overflow: hidden;

    &__icon {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;
    }

    &--highlighted {
      border-color: @cBaseTwo;
    }

    &--opened &__icon {
      transform: rotate(-180deg);
    }

    &--focused {
      border-color: @cBaseThree;
      outline: none;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    display: block;

    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 10%;
      z-index: 2;
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid @cBaseThree;
    }

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 10%;
      z-index: 2;
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid @cBaseOne;
    }
  }

  &__no-data {

  }

  &__options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-height: 36px * 5;
    overflow-y: auto;
    background-color: @cBaseOne;
    border: @sizeBorderDefault solid @cBaseThree;
    border-radius: 4px;
    box-shadow: 0 0 10px @cBaseTwo;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style: none;
    font-family: @ffOne;
  }

  &__option {
    padding: 0 8px;
    line-height: 36px;
    white-space: nowrap;

    &--selected {
      color: @cBaseFour;
      font-family: @ffOne;
      font-size: 16px;
    }

    &--highlighted {
      background-color: #f5f5f5;
      cursor: pointer;
      transition: 0.1s ease-in-out;
    }
  }

  /*Animations*/

  &__slide-fade {
    &-enter-active, &-leave-active {
      transition: 0.2s ease-in-out;
    }

    &-enter, &-leave-to {
      transform: translateY(-10px);
      opacity: 0;
    }
  }
}
</style>
