<template>
  <div class="file">
    <input
      type="file"
      ref="inputFile"
      hidden
      @change="emitVal"
    >
    <button
      class="file__btn"
      :class="value ? 'file__btn--active' : ''"
      :type="status"
      @click="clickSelect"
    >
      {{ compNameFile }}
      <span
        v-if="multi"
        class="file__btn__multi"
        @click.stop="() => multiChange()"
      >МУЛЬТИ</span>
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import MultiFileUpModal from "@/components/modals/MultiFileUpModal.vue";

export default {
  name: "InputFile",
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      message: 'Выбрать файл',
      status: 'default'
    }
  },
  props: {
    value: {
      type: File,
      default: null
    },
    category: {
      type: String,
      default: ''
    },
    formats: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 1024, // Kb
    },
    multi: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    compNameFile () {
      return this.value ? this.value.name : this.message;
    },
  },
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    clickSelect () {
      this.$refs.inputFile.click();
    },
    emitVal (e) {
      let file = e.target.files[0];

      if (file.size > this.maxSize * 1024) {
        this.message = 'Файл превышает ' + this.maxSize + ' Кб';
        this.status = 'error';
        return;
      }

      // if (file.type.indexOf(this.category.toLowerCase()) < 0) {
      //   this.message = 'Тип файла не соответствует доступным форматам ' + this.formats;
      //   this.status = 'error';
      //   return;
      // }

      // if ((this.formats.toLowerCase()).indexOf(file.type.substr(file.type.indexOf('/') + 1)) < 0) {
      //   this.message = 'Формат файла не соответствует доступным форматам ' + this.formats;
      //   this.status = 'error';
      //   return;
      // }

      this.status = 'success';
      this.$emit('change', file);
    },
    multiChange () {
      this.openModal({
        component: MultiFileUpModal,
        params: {
          loadFile: (file) => new Promise((resolve) => {
            this.$emit('change', file, {
              callback: () => {
                resolve()
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.file {

  &__btn {
    width: 100%;
    line-height: 36px;
    padding: 0 12px;
    margin-top: 20px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: start;
    color: #C2C2C2;
    background: white;
    border-radius: 2px;
    outline: none;
    border: 1px solid var(--cBase2);
    cursor: pointer;
    position: relative;

    &--active {
      color: #022E40;
    }

    &__multi {
      position: absolute;
      right: 8px;
      top: 8px;
      bottom: 8px;
      line-height: 19px;
      padding: 2px 5px;
      background: #cccccc;
      color: white;
      border-radius: 4px;

      &:hover {
        background: #a4a4a4;
      }
    }
  }
}
</style>
