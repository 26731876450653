import { ApiClient } from "../api";
export const requestEntities = () => ApiClient
    .setup(ApiClient.GET, (listRoutes) => listRoutes.admin.get)
    .request();
export const requestNodes = ({ hash, code, ...params }) => ApiClient
    .setup(ApiClient.GET, (listRoutes) => listRoutes.admin.nodes)
    .request({ hash, code, ...params });
export const requestSettings = () => ApiClient
    .setup(ApiClient.GET, (listRoutes) => listRoutes.admin.getConfigSettings)
    .request();
export const requestSaveSettings = (data) => ApiClient
    .setup(ApiClient.POST, (listRoutes) => listRoutes.admin.saveConfigSettings)
    .setFormData(data)
    .request();
export const requestAuthCheck = (data) => ApiClient
    .setup(ApiClient.GET, (listRoutes) => listRoutes.auth.check)
    .request(data);
