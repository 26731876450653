import { ApiClient } from "@/services/api";
import router from "../router";
import { getDeepDependRoles } from "@/services/user";

export default {
  namespaced: true,
  state: {
    user: null,
    users: []
  },
  getters: {
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    getUserById: (state) => (id) => state.users.find(i => i.id == id),
    userHasRole: (state) => (role) => state.user.nestedRoles.has(role)
  },
  mutations: {
    setUser: (state, payload) => {
      state.user = payload
      state.user.nestedRoles = getDeepDependRoles(payload.roles)

      localStorage.setItem("userToken", payload.token);
      localStorage.setItem("userUsername", payload.username);
      ApiClient.setAutoAuthHeaders()
    },
    removeUser: (state) => {
      state.user = null
      localStorage.removeItem("userToken");
      localStorage.removeItem("userUsername");
    },
    setUsers: (state, payload) => {
      state.users = payload
    },
    addUser: (state, payload) => {
      state.users.push(payload)
    },
  },
  actions: {
    loadUsers: ({ commit }) => {
      return ApiClient.setup(ApiClient.GET, (t) => t.admin.getUsers).request().then((r) => {
        commit('setUsers', r.data.users)
      })
    },
    loadUser: ({ commit }, payload) => {
      return ApiClient.setup(ApiClient.GET, (t) => t.admin.getUser).request(payload).then((r) => {
        commit('addUser', r.data.user)
      })
    },
    logIn: ({ commit }, payload) => {
      return ApiClient.setup(ApiClient.GET, (t) => t.auth.login).request(payload).then((r) => {
        commit('setUser', r.data.user)
        router.push({ name: 'HOME' })
      })
    },
    createUser: (_, payload) => {
      return ApiClient.setup(ApiClient.POST, (t) => t.admin.createUser).setFormData(payload).request().then(() => {
        router.push({ name: 'HOME' })
      })
    },
    updateUser: ({ commit }, payload) => {
      return ApiClient.setup(ApiClient.PUT, (t) => t.admin.updateUser).setData(payload).request().then((r) => {
        commit('setUser', r.data.user)
        router.push({ name: 'HOME' })
      })
    },
  }
}
