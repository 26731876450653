<template>
  <PageLayout>
    <RouterView />
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import { user } from "@/mixins/user";
import { mapActions } from "vuex";

export default {
  name: 'MainPage',
  mixins: [
    user
  ],
  components: {
    PageLayout
  },
  mounted () {
    this.loadEntities()
  },
  methods: {
    ...mapActions('entities', [
      'loadEntities'
    ]),
    customLabel (option) {
      return (`${option.name} - ${option.value}`)
    }
  }
}
</script>

<style>
</style>
