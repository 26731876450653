<template>
  <div
    class="form-control"
    :class="'form-control--' + size"
  >
    <div
      v-if="label"
      class="form-label active form-label--rich"
    >
      <div>
        {{ label }}
      </div>
      <div class="html-mode" :class="{ active: htmlMode }" @click="() => changeMode()">
        HTML режим
      </div>
    </div>
    <div v-if="changeStatus" class="form-input-wrapper">
      <QuillEditor
        v-if="!htmlMode"
        :content="value"
        :options="options"
        class="form-control__rich"
        @change="(v) => setValue(v.html)"
      />
      <textarea
        v-else
        :value="valueHTML"
        class="form-input form-control__rich"
        @input="(v) => setValue(`<html>${v.target.value}</html>`)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor as QuillEditor } from 'vue-quill-editor'

export default {
  name: "RichText",
  components: {
    QuillEditor
  },
  data () {
    return {
      forceLabel: false,
      htmlMode: false,
      changeStatus: true
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    getIconStatus () {
      if (this.status === 'success') {
        return 'check'
      } else if (this.status === 'error') {
        return 'exclamation-circle'
      } else {
        return false;
      }
    },
    labelUp () {
      return (this.value && this.value.length > 0) || this.forceLabel;
    },
    options () {
      return {
        placeholder: 'Введите'
      }
    },
    valueHTML () {
      return this.value.slice(6, this.value.length - 7)
    }
  },
  mounted () {
    console.dir(this.value)
    this.htmlMode = this.value.indexOf('<html>') === 0
  },
  methods: {
    setValue (v) {
      this.$emit('input', v)
    },
    setForceLabel (v = true) {
      if (v) this.$refs.input.focus();
      this.forceLabel = v;
    },
    changeMode () {
      this.changeStatus = false

      this.htmlMode = !this.htmlMode

      if (this.htmlMode) {
        this.setValue(`<html>${this.value}</html>`)
      } else {
        this.setValue(this.value.slice(6, this.value.length - 7))
      }

      this.changeStatus = true
    }
  }
}
</script>

<style scoped lang="less">
.form-control {
  @padding-form: 12px 14px 10px;
  position: relative;

  &__rich {
    background-color: white;
  }

  .form-label {
    position: absolute;
    padding: 0 14px;
    font-family: @ffOne;
    font-size: 16px;
    color: #C2C2C2;
    transition: 0.2s ease-in-out;
    top: 8px;
    z-index: 1;
    user-select: none;
    cursor: text;

    &--rich {
      display: flex;
      justify-content: space-between;
      left: 0;
      right: 0;
    }

    &.active {
      font-size: 14px;
      top: -20px;
      color: @cBaseThree;
    }

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  .html-mode {
    background: #ffd2d2;
    border-radius: 30px;
    padding: 0 6px;
    line-height: 16px;
    height: 16px;
    cursor: pointer;

    &.active {
      background: #b1eebd;
    }
  }

  .form-input {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    color: @cBaseThree;
    padding: 0 12px;
    line-height: 20px;
    box-sizing: border-box;
    transition: 0.2s;
    font-family: @ffOne;
    font-size: 14px;
    background: white;
    border-radius: 2px;
    outline: none;
    border: @sizeBorderDefault solid @cBaseTwo;
    border-bottom: @sizeBorderDefault solid @cBaseTwo;

    &:focus {
      border: @sizeBorderDefault solid @cBaseThree;
    }

    &-wrapper {
      position: relative;
      margin-top: 20px;
    }

    &::placeholder {
      color: #bfbfbf;
    }

    &.success {
      border-bottom: @sizeBorderDefault solid #75dc75;

      &:focus {
        border: @sizeBorderDefault solid #75dc75;
        border-bottom: @sizeBorderDefault solid #75dc75;
      }
    }

    &.error {
      border-bottom: @sizeBorderDefault solid #cc524a;

      &:focus {
        border: @sizeBorderDefault solid #cc524a;
        border-bottom: @sizeBorderDefault solid #cc524a;
      }
    }

    &[disabled] {
      border-bottom: @sizeBorderDefault solid #777;
    }
  }

  & .icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: @padding-form;

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  & .dialog {
    font-family: @ffOne;
    font-size: 14px;
    padding: 5px 10px;
    color: #cc524a;
    font-weight: bold;
  }

  &--mini {
    .form-input {
      padding: 0 14px;
      line-height: 30px;
      border-radius: 4px;
    }

    .form-label {
      top: 24px;

      &.active {
        top: -2px;
        color: @cBaseThree;
      }
    }
  }
}
</style>

<style lang="less">
.form-control__rich {
  & .ql-snow {
    border-color: @cBaseTwo;
  }
}
</style>
