import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/pages/Home.vue';
import MainPage from '../components/pages/Main.vue';
import CreateEntityPage from '../components/pages/entities/CreateEntity.vue';
import EditEntityPage from '../components/pages/entities/EditEntity.vue';
import EntityPage from '../components/pages/entities/Entity.vue';
import CreateNodePage from '../components/pages/entities/CreateNode.vue';
import EditNodePage from '../components/pages/entities/EditNode.vue';
import AuthPage from '../components/pages/Auth.vue';
import AdminSettings from '../components/pages/admin/AdminSettings.vue';
import SettingsPage from '../components/pages/Settings.vue';
import UsersPage from '../components/pages/users/index.vue';
import EditUserPage from '../components/pages/users/EditUser.vue';
import CreateUserPage from '../components/pages/users/CreateUser.vue';
import { USER_ROLE } from '../services/user';
Vue.use(VueRouter);
export var ROUTE_NAMES;
(function (ROUTE_NAMES) {
    ROUTE_NAMES["AUTH"] = "AUTH";
    ROUTE_NAMES["HOME"] = "HOME";
    ROUTE_NAMES["MAIN"] = "MAIN";
    ROUTE_NAMES["CHECK_AUTH"] = "CHECK_AUTH";
    ROUTE_NAMES["SETTINGS"] = "SETTINGS";
    ROUTE_NAMES["ADMIN_SETTING"] = "ADMIN_SETTING";
    ROUTE_NAMES["USERS"] = "USERS";
    ROUTE_NAMES["CREATE_USER"] = "CREATE_USER";
    ROUTE_NAMES["EDIT_USER"] = "EDIT_USER";
    ROUTE_NAMES["ENTITY"] = "ENTITY";
    ROUTE_NAMES["CREATE_ENTITY"] = "CREATE_ENTITY";
    ROUTE_NAMES["EDIT_ENTITY"] = "EDIT_ENTITY";
    ROUTE_NAMES["CREATE_NODE"] = "CREATE_NODE";
    ROUTE_NAMES["EDIT_NODE"] = "EDIT_NODE";
})(ROUTE_NAMES || (ROUTE_NAMES = {}));
const routes = [
    {
        path: '/auth',
        name: ROUTE_NAMES.AUTH,
        component: AuthPage,
        meta: {
            onlyAccessRoles: true,
            accessRoles: [
                USER_ROLE.ANONYMOUSLY
            ],
            redirect: ROUTE_NAMES.HOME // redirect without role
        }
    },
    {
        path: '/',
        name: ROUTE_NAMES.MAIN,
        component: MainPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        },
        children: [
            {
                path: '/entity/create',
                name: ROUTE_NAMES.CREATE_ENTITY,
                component: CreateEntityPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
            {
                path: '/entity/:entity',
                name: ROUTE_NAMES.ENTITY,
                component: EntityPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
            {
                path: '/entity/:entity/create',
                name: ROUTE_NAMES.CREATE_NODE,
                component: CreateNodePage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
            {
                path: '/entity/:entity/:node/edit',
                name: ROUTE_NAMES.EDIT_NODE,
                component: EditNodePage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
            {
                path: '/entity/:entity/edit',
                name: ROUTE_NAMES.EDIT_ENTITY,
                component: EditEntityPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
            {
                path: '/admin/settings',
                name: ROUTE_NAMES.ADMIN_SETTING,
                component: AdminSettings,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                    redirect: ROUTE_NAMES.HOME
                }
            },
            {
                path: '/settings',
                name: ROUTE_NAMES.SETTINGS,
                component: SettingsPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                    redirect: ROUTE_NAMES.HOME
                }
            },
            {
                path: '/users',
                name: ROUTE_NAMES.USERS,
                component: UsersPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_ADMIN
                    ],
                    redirect: ROUTE_NAMES.HOME
                }
            },
            {
                path: '/users/create',
                name: ROUTE_NAMES.CREATE_USER,
                component: CreateUserPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_ADMIN
                    ],
                    redirect: ROUTE_NAMES.HOME
                }
            },
            {
                path: '/users/:id',
                name: ROUTE_NAMES.EDIT_USER,
                component: EditUserPage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_ADMIN
                    ],
                    redirect: ROUTE_NAMES.HOME
                }
            },
            {
                path: '/*',
                name: ROUTE_NAMES.HOME,
                component: HomePage,
                meta: {
                    accessRoles: [
                        USER_ROLE.ROLE_MODERATOR
                    ],
                }
            },
        ]
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router;
