<template>
  <div class="multi-select__wrapper">
    <div class="multi-select__label">
      {{ label }}
    </div>
    <div class="multi-select" @click="() => toSelect()">
      <span class="multi-select__text">{{ labelValues }}</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import MultiSelectModal from "@/components/modals/MultiSelectModal";

export default {
  name: "MultiSelect",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    label: {
      type: String,
      default: '...'
    }
  },
  computed: {
    labelValues () {
      if (!this.value || !this.value.length) {
        return this.placeholder
      }

      return this.value.length ? 'Выбрано: ' + this.value.length : 'Выбрать'
    }
  },
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    toSelect () {
      this.openModal({
        component: MultiSelectModal,
        params: {
          value: this.value,
          config: this.config,
          changeCallback: (value) => this.$emit('change', value)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.multi-select {
  width: 100%;
  line-height: 36px;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: start;
  color: #C2C2C2;
  background: white;
  border-radius: 2px;
  outline: none;
  border: 1px solid var(--cBase2);
  cursor: pointer;
  font-family: @ffOne;
  overflow: hidden;

  &__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
    height: 20px;
    font-family: @ffOne;
    font-size: 14px;
    line-height: 20px;
    padding: 0 14px;
  }
}
</style>
