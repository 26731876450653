<template>
  <svg class="sort-button" @click="() => sortTo()" width="10" height="15" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="isSortAsc ? '#D94E67' : '#999999'" d="M1.80455 4.35038L3.66652 2.52163C3.72903 2.46026 3.8138 2.42578 3.90219 2.42578C3.99057 2.42578 4.07534 2.46026 4.13785 2.52163L5.99982 4.35038C6.04642 4.39617 6.07816 4.4545 6.09101 4.51799C6.10387 4.58149 6.09727 4.6473 6.07204 4.70712C6.04682 4.76693 6.00411 4.81805 5.9493 4.85403C5.8945 4.89 5.83007 4.90921 5.76416 4.90922L2.04022 4.90922C1.9743 4.90921 1.90987 4.89 1.85507 4.85403C1.80026 4.81805 1.75755 4.76693 1.73233 4.70712C1.70711 4.6473 1.7005 4.58149 1.71336 4.51799C1.72622 4.4545 1.75795 4.39617 1.80455 4.35038Z"/>
    <path :fill="isSortDesc ? '#D94E67' : '#999999'" d="M2.13794 7.08594H5.86188C5.9278 7.08595 5.99223 7.10516 6.04703 7.14113C6.10184 7.17711 6.14455 7.22823 6.16977 7.28804C6.19499 7.34786 6.2016 7.41367 6.18874 7.47717C6.17588 7.54066 6.14415 7.59899 6.09755 7.64478L4.23558 9.47352C4.17307 9.5349 4.0883 9.56937 3.99991 9.56937C3.91153 9.56937 3.82676 9.5349 3.76425 9.47352L1.90228 7.64478C1.85568 7.59899 1.82394 7.54066 1.81109 7.47717C1.79823 7.41367 1.80483 7.34786 1.83006 7.28804C1.85528 7.22823 1.89799 7.17711 1.95279 7.14113C2.0076 7.10516 2.07203 7.08595 2.13794 7.08594Z" />
  </svg>
</template>

<script>
const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export default {
  name: 'SortButton',
  props: {
    field: {
      type: String
    },
    order: {
      type: String,
    },
    active: {
      type: String,
    },
  },
  computed: {
    isSort () {
      return this.field === this.active
    },
    isSortAsc () {
      return this.isSort && (this.order === SortDirection.ASC)
    },
    isSortDesc () {
      return this.isSort && (this.order === SortDirection.DESC)
    }
  },
  methods: {
    sortTo () {
      const sort = {
        field: this.field,
        order: SortDirection.ASC
      }

      if (this.isSort) {
        if (this.order === SortDirection.ASC) {
          sort.order = SortDirection.DESC
        } else if (this.order === SortDirection.DESC) {
          delete sort.order
        }
      }
      if (!sort.order) {
        delete sort.sort
      }
      this.$emit('sortTo', sort)
    }
  }
}
</script>

<style scoped lang="less">
.sort-button {
  cursor: pointer;
}
</style>
