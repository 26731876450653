<template>
  <ModalRCMS>
    <span @click="() => $emit('close')">
      <img :src="params.src" alt="">
    </span>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";

export default {
  name: "PicturePreview",
  components: {
    ModalRCMS
  },
  props: {
    params: Object
  },
  mounted() {
    console.log('Ya tut')
  }
}
</script>

<style scoped>

</style>
