<template>
  <div>
    <section v-if="getEntityData" class="p-16">
      <div class="d-flex w-100 justify-sb">
        <div class="entity__info">
          <div class="entity__info__name">
            <IconRCMS
              v-if="getEntityData.config && getEntityData.config.icon"
              :icon="getEntityData.config.icon"
            />
            <h2>
              {{ getEntityData.title }}
            </h2>
          </div>
          <div class="entity__details">
            Всего: {{ getCountAll }} <span v-if="getCountAll !== getCount">(с фильтром: {{ getCount }})</span>
          </div>
        </div>
        <div class="entity__nav">
          <CircleBtn
            v-if="userHasRole(userRoles.ROLE_ADMIN)"
            icon="trash"
            type="error"
            @click="() => successRemoveEntity(getEntityData.hash)"
          />
          <CircleBtn
            icon="sliders"
            @click="() => openFilters()"
          />
          <RouterLink
            v-if="userHasRole(userRoles.ROLE_ADMIN)"
            :to="{ name: $routeNames.EDIT_ENTITY, params: { entity: getEntityData.code } }"
          >
            <CircleBtn icon="edit" />
          </RouterLink>
          <RouterLink :to="{ name: $routeNames.CREATE_NODE, params: { entity: getEntityData.code } }">
            <CircleBtn icon="plus" />
          </RouterLink>
        </div>
      </div>
    </section>

    <section v-if="selectedNodes.size" class="p-16">
      Выбрано записей: {{ selectedNodes.size }}
      <CustomBtn @click="() => removeAllSelected()">Удалить выбранные</CustomBtn>
      <CustomBtn @click="() => removeAllSelected(true)">Удалить все</CustomBtn>
    </section>

    <section class="p-16">
      <div class="rcms-table">
        <div class="rcms-table__header" :style="columnsSizesStyle">
          <div class="rcms-table__header__column rcms-table__header__column--select">
            <Checkbox :value="getCountAll === selectedNodes.size" @change="(v) => selectNodes(v, 'LOADED')" />
          </div>
          <div
            v-for="field in columns"
            :key="field.hash"
            class="rcms-table__header__column"
          >
            {{ field.title }}
            <SortButton
              :active="sort.field"
              :order="sort.order"
              :field="field.hash"
              @sortTo="(v) => sortTo(v, field)"
            />
          </div>
          <div style="width: 100px"></div>
        </div>
        <div v-if="getNodes && getNodes.length" class="rcms-table__rows">
          <div
            v-for="(node) in getNodes"
            :key="node.hash"
            class="rcms-table__row"
            :style="columnsSizesStyle"
          >
            <div class="rcms-table__column rcms-table__column--select">
              <Checkbox :value="selectedNodes.has(node.hash)" @change="(v) => selectNodes(v, node.hash)" />
            </div>
            <div
              v-for="field in columns"
              :key="field.hash"
              class="rcms-table__column"
              :style="{ maxWidth: `${ field.config && field.config.maxWidth || 200 }px`, flex: 1 }"
            >
              <MediaPreview
                v-if="field.type === 'media' && node.fields[field.hash]"
                :field="field"
                :media="node.fields[field.hash]"
              />
              <template v-else-if="field.type === 'relation'">
                {{ relationName(node, field) }}
              </template>
              <span v-else class="text-limit" :title="node.fields[field.hash]">
                {{ node.fields[field.hash] }}
              </span>
            </div>
            <div class="rcms-table__row__nav" style="width: 100px">
              <RouterLink :to="{ name: 'EDIT_NODE', params: { entity: $route.params.entity, node: node.hash } }">
                <CircleBtn icon="edit" />
              </RouterLink>
              <CircleBtn @click="() => removeNodeHandler({ hash: node.hash })" icon="trash" type="error"/>
            </div>
          </div>
        </div>
        <div v-else class="rcms-table__empty">Записей нет</div>
      </div>
    </section>
    <section class="p-16">
      <Pagination v-model="pagination" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import CircleBtn from "@/components/ui/CircleBtn";
import CustomBtn from "@/components/ui/Btn";
import Checkbox from "@/components/ui/Checkbox";
import Pagination from "@/components/ui/Pagination";
import MediaPreview from "@/components/parts/MediaPreview";
import SortButton from "@/components/parts/SortButton.vue";
import IconRCMS from "@/components/ui/Icon";
import { filtersModal, helpModal, picturePreviewModal } from "@/mixins/modals";
import { user } from "@/mixins/user";

export default {
  name: 'EntityPage',
  components: {
    Checkbox,
    CircleBtn,
    CustomBtn,
    Pagination,
    MediaPreview,
    SortButton,
    IconRCMS
  },
  data () {
    return {
      paginationLimit: 10,
      paginationPage: 1,
      selectedNodes: new Set()
    }
  },
  mixins: [picturePreviewModal, helpModal, user, filtersModal],
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode',
      'getEntityByHash'
    ]),
    ...mapGetters('nodes', [
      'getCountAll',
      'getCount',
      'getNodes',
      'getRelateNodes',
    ]),
    configPage () {
      return {
        filters: this.$route.query && this.$route.query.filters || '',
        limit: this.pagination.limit,
        page: this.pagination.page,
        sort: this.$route.query && this.$route.query.sort && this.$route.query.sort || '',
      }
    },
    sort () {
      const sort = this.$route.query.sort
      return sort ? JSON.parse(sort) : {}
    },
    getEntityCode () {
      return this.$route.params.entity
    },
    title () {
      return 'Страницы'
    },
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity)
    },
    columns () {
      return this.getEntityData && Object.values(this.getEntityData.fields).filter(f => f.pin)
    },
    columnsSizesStyle () {
      const sizes = ['40px']
      const fr = 'minmax(200px, 1fr)'
      let frContain = false
      this.columns.forEach(i => {
        const maxWidth = i.config && i.config.maxWidth
        if (maxWidth) {
          sizes.push(`${ maxWidth }px`)
        } else {
          if (frContain) {
            sizes.push(`200px`)
          } else {
            sizes.push(fr)
          }
          frContain = true
        }
      })

      sizes.push(sizes.includes(fr) ? '120px' : fr)
      return {
        gridTemplateColumns: sizes.join(' ')
      }
    },
    pagination: {
      get () {
        return {
          limit: this.paginationLimit,
          page: this.paginationPage,
          count: this.getCount
        }
      },
      set (v) {
        this.paginationPage = v.page
        this.paginationLimit = v.limit
      }
    }
  },
  mounted () {
    this.loadNodesHandler()
  },
  methods: {
    ...mapActions('entities', [
      'removeEntity',
      'loadEntities'
    ]),
    ...mapActions('nodes', [
      'loadNodes',
      'removeNode',
      'removeSelectedNodes'
    ]),
    ...mapMutations('forms/entity', [
      'setForm'
    ]),
    loadNodesHandler () {
      this.loadNodes({
        code: this.$route.params.entity,
        params: this.configPage
      })
    },
    removeNodeHandler (payload) {
      this.openHelpModal({
        title: 'Подтвердите действие',
        message: 'Вы уверены что хотите удалить запись?',
        buttons: [
          {
            type: 'danger',
            text: 'Удалить',
            click: () => this.removeNodeHandlerRun(payload),
            afterClick: 'emitClose'
          },
          {
            type: 'default',
            text: 'Отмена',
            click: 'emitClose'
          },
        ]
      })
    },
    removeNodeHandlerRun (payload) {
      this.removeNode(payload)
        .then(() => {
          this.loadNodesHandler()
        })
    },
    openFilters () {
      this.openFilterModal({
        filter: {},
        entity: this.getEntityData
      })
    },
    successRemoveEntity (id) {
      this.openHelpModal({
        title: 'Подтвердите действие',
        message: 'Вы уверены что хотите удалить сущность?',
        buttons: [
          {
            type: 'danger',
            text: 'Удалить',
            click: () => this.removeEntityHandler(id),
            afterClick: 'emitClose'
          },
          {
            type: 'default',
            text: 'Отмена',
            click: 'emitClose'
          },
        ]
      })
    },
    removeEntityHandler (id) {
      this.removeEntity(id)
        .then(() => {
          this.loadEntities({ force: true })
          this.$router.push({ name: this.$routeNames.HOME })
        })
    },
    relationName (node, field) {
      if (!node || !field) {
        return ''
      }
      const value = node.fields[field.hash]

      if (!value || !(field.config && field.config.entityHash)) {
        return ''
      }
      const relateEntity = this.getEntityByHash(field.config.entityHash)
      if (!relateEntity) {
        return ''
      }
      const relateNodes = value.map(hash => this.getRelateNodes[hash])
      if (!relateNodes) {
        return ''
      }
      return relateNodes
        .filter((rn => rn))
        .map(rn => rn.fields[relateEntity.config.mainField]).join(', ')
    },
    sortTo (v, field) {
      console.log(field)
      this.$router.push({ query: { ...this.$route.query, sort: JSON.stringify(v) } })
    },
    selectNodes (v, key) {
      if (key === 'LOADED') {
        this.selectedNodes = new Set((v ? this.getNodes.map(i => i.hash) : []))
      } else if (key === 'ALL') {
        this.selectedNodes = []
      } else {
        if (v) {
          this.selectedNodes.add(key)
        } else {
          this.selectedNodes.delete(key)
        }
        this.selectedNodes = new Set(this.selectedNodes)
      }
    },
    removeAllSelected (isAll = false) {
      const params = {}
      if (this.getCountAll === this.selectedNodes.size || isAll) {
        params.entityHash = this.getEntityData.hash
      } else {
        params.nodes = Array.from(this.selectedNodes)
      }

      this.removeSelectedNodes(params)
        .then(() => {
          this.loadNodesHandler()
          this.selectedNodes = new Set()
        })
    }
  },
  watch: {
    getEntityCode: {
      handler () {
        this.loadNodesHandler()
      }
    },
    configPage: {
      handler (v, o) {
        if (JSON.stringify(v) !== JSON.stringify(o)) {
          this.loadNodesHandler()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.entity {

  &__info {
    &__name {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-family: @ffOne;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    color: var(--cBase3);
  }

  &__details {
    padding-top: 4px;
    font-size: 16px;
    font-weight: 700;
    font-family: @ffTwo;
    color: var(--cBase3);
  }

  &__nav {
    display: flex;
    gap: 6px;
  }
}
</style>
