<template>
  <button class="btn" :class="'btn--' + type" @click="() => clickBtn()">
    <slot>Кнопка</slot>
  </button>
</template>

<script>
export default {
  name: "CustomBtn",
  props: {
    type: {
      default: 'default',
      type: String,
    }
  },
  methods: {
    clickBtn () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="less">
.btn {
  border: none;
  cursor: pointer;
  font-weight: 700;
  color: @cBaseThree;
  line-height: 36px;
  padding: 0 14px;
  min-width: 100px;
  font-family: @ffOne;
  transition: 0.2s;

  &:hover {
    background-color:  #ffc1cc;
  }

  &--danger, &--error {
    background-color: #e08b9a;

    &:hover {
      background-color: #cb6f7f;
    }
  }
}
</style>
