<template>
  <div>
    <section class="p-16">
      <div style="display: flex; gap: 10px">
        <SelectRCMS v-model="entity" :options="entityOptions" label="Выберите сущность" />
        <SelectRCMS v-if="showNodeSelect" v-model="node" :options="nodeOptions" label="Выберите запись" />
      </div>
      <button @click="() => saveSettings()">Сохранить</button>
    </section>
  </div>
</template>

<script>
import SelectRCMS from '@/components/ui/Select'
import { requestNodes, requestSettings, requestSaveSettings } from '@/services/api/requests'
import { mapGetters } from "vuex";

export default {
  name: 'AdminSettingsPage',
  components: {
    SelectRCMS
  },
  data() {
    return {
      entity: "",
      node: "",
      nodes: []
    };
  },
  computed: {
    ...mapGetters('entities', [
      'getEntities'
    ]),
    showNodeSelect () {
      return this.entity && this.nodes.length
    },
    entityOptions () {
      return this.getEntities.map(entity => ({ name: entity.title, value: entity.hash }));
    },
    nodeOptions () {
      console.log(this.nodes.map(node => ({ name: `${ JSON.stringify(Object.values(node.fields)) }`, value: node.hash })))
      return this.nodes.map(node => ({ name: `${ JSON.stringify(Object.values(node.fields)) }`, value: node.hash }));
    }
  },
  watch: {
    entity: {
      handler (v) {
        this.loadNodes(v)
      }
    }
  },
  mounted () {
    requestSettings()
      .then((response) => {
        this.entity = response.data.entityHash
        this.node = response.data.nodeHash
        this.loadNodes(this.entity)
      })
  },
  methods: {
    loadNodes (v) {
      if (!v) {
        return
      }
      requestNodes({ hash: v })
        .then((response) => {
          this.nodes = response.data.nodes
        })
    },
    saveSettings () {
      requestSaveSettings({
        node: this.node,
        entity: this.entity
      })
    }
  }
}
</script>

<style>
</style>
