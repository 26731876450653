import { ApiClient } from "@/services/api";

const newForm = {
  hash: '',
  title: '',
  code: '',
  order: 0,
  pin: false,
  config: {},
  fields: []
}

export default {
  namespaced: true,
  state: {
    hash: '',
    title: '',
    code: '',
    pin: false,
    config: {},
    order: 0,
    fields: []
  },
  getters: {
    getForm: (state) => state,
    getFields: (state) => state.fields,
    getField: (state) => (field) => state[field]
  },
  mutations: {
    clear: (state) => {
      Object.keys(newForm).forEach((key) => { state[key] = newForm[key] })
    },
    setField: (state, { field, value }) => {
      state[field] = value
    },
    setOrderOfFields: (state, payload) => {
      payload.forEach((field, index) => {
        field.order = index + 1
      })
      state.fields = payload
    },
    setConfig: (state, config) => {
      state.config = {
        ...state.config,
        ...config
      }
    },
    setForm: (state, form) => {
      if (!form) {
        return
      }

      Object.keys(form).forEach((key) => { state[key] = form[key] })
    },
    addCustomField: (state, type) => {
      state.fields.push({
        type,
        config: {}
      })
    },
    editTitleCustomField: (state, { title, index }) => {
      state.fields[index].title = title
      state.fields = [...state.fields]
    },
    editCodeCustomField: (state, { code, index }) => {
      state.fields[index].code = code
      state.fields = [...state.fields]
    },
    editFieldCustomField: (state, { value, field, index }) => {
      state.fields[index][field] = value
      state.fields = [...state.fields]
    },
    removeCustomField: (state, { field, index }) => {
      if (field.hash) {
        state.fields[index].deleted = true
        state.fields = [...state.fields]
      } else {
        state.fields.splice(index, 1)
      }
    },
    setConfigFieldCustomField: (state, { config, index }) => {
      state.fields[index].config = config
      state.fields = [...state.fields]
    },
    setConfigFieldCustomFieldByField: (state, { value, field, index }) => {
      state.fields[index].config = state.fields[index].config ? { ...state.fields[index].config } : {}
      state.fields[index].config[field] = value
      state.fields[index].config = { ...state.fields[index].config }
      state.fields = [...state.fields]
    },
  },
  actions: {
    save: ({ state, commit }) => {
      commit('setLoader', { loader: 'content', status: true }, { root: true })
      const isNew = !state.hash
      return ApiClient
        .setup(ApiClient.POST, (listRoutes) => (isNew ? listRoutes.admin.createEntity : listRoutes.admin.editEntity))
        .setFormData({
          ...state
        })
        .request()
        .then((response) => {
          commit('setLoader', { loader: 'content', status: false }, { root: true })
          commit(isNew ? 'entities/addEntity' : 'entities/updateEntity', response.data, { root: true })

          return response.data
        })
    }
  }
}
