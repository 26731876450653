<template>
  <ModalRCMS>
    <section class="p-16" style="width: 600px">
      <h2>
        Конфигурация поля
      </h2>

      <div>
        <SwitchRCMS
          v-model="slugMode"
          label="SLUG"
        />
        <SelectRCMS
          v-if="slugMode"
          v-model="form.slug"
          :options="getOptionsFields"
          :disabled="false"
          no-data-message="Пусто"
          label="Выберите поле"
        />
      </div>

      <div>
        <SwitchRCMS
          v-model="fileNameMode"
          label="Имя файла"
        />
        <SelectRCMS
          v-if="fileNameMode"
          v-model="form.fileName"
          :options="getOptionsFieldsFiles"
          :disabled="false"
          no-data-message="Пусто"
          label="Выберите поле"
        />
      </div>

      <CustomBtn @click="() => save()">Сохранить</CustomBtn>
    </section>
  </ModalRCMS>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import ModalRCMS from "@/components/parts/Modal";
import SwitchRCMS from "@/components/ui/Switch";
import SelectRCMS from "@/components/ui/Select";
import CustomBtn from "@/components/ui/Btn";
import { TYPE_FIELD } from "@/const";

export default {
  name: "NumberFieldConfig",
  components: {
    ModalRCMS,
    SwitchRCMS,
    CustomBtn,
    SelectRCMS
  },
  props: {
    params: Object
  },
  data () {
    return {
      slugMode: false,
      fileNameMode: false,
      form: {
        slug: '',
        fileName: ''
      }
    }
  },
  computed: {
    ...mapGetters('forms/entity', [
      'getFields',
    ]),
    getOptionsFields () {
      return this.getFields
        .filter(field => field.type === TYPE_FIELD.TEXT &&
          ((field.hash || field.code) !== (this.params.field.hash || this.params.field.code))
        )
        .map(field => ({ name: field.title, value: field.hash || field.code }))
    },
    getOptionsFieldsFiles () {
      return this.getFields
        .filter(field => field.type === TYPE_FIELD.MEDIA &&
          ((field.hash || field.code) !== (this.params.field.hash || this.params.field.code))
        )
        .map(field => ({ name: field.title, value: field.hash || field.code }))
    }
  },
  mounted() {
    if (this.params.field.config) {
      this.form.slug = this.params.field.config.slug
      if (this.form.slug) {
        this.slugMode = true
      }
      this.form.fileName = this.params.field.config.fileName
      if (this.form.fileName) {
        this.fileNameMode = true
      }
    }
  },
  methods: {
    ...mapMutations('forms/entity', [
      'setConfigFieldCustomField',
    ]),
    save () {
      this.setConfigFieldCustomField({
        config: {
          ...this.params.field.config,
          ...this.form
        },
        index: this.params.index
      })
      this.$emit('close')
    }
  }
}
</script>
