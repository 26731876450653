import { ROUTE_NAMES } from "./index";
import { checkAccessByRoles, USER_ROLE } from "../services/user";
import { ApiClient } from "../services/api";
import { requestAuthCheck } from "../services/api/requests";
const main = (to, from, next, store) => new Promise((resolve, reject) => {
    const user = store.getters['users/getUser'];
    const accessRoles = to.meta?.accessRoles || [USER_ROLE.ANONYMOUSLY];
    const userNestedRoles = user && user.nestedRoles || new Set([USER_ROLE.ANONYMOUSLY]);
    const userRoles = user && user.roles || [USER_ROLE.ANONYMOUSLY];
    if (to.meta?.onlyAccessRoles && !checkAccessByRoles(accessRoles, userRoles)) {
        return reject({
            name: to.meta.redirect,
            params: { name: user.username }
        });
    }
    if (checkAccessByRoles(accessRoles, Array.from(userNestedRoles))) {
        return resolve(true);
    }
    return reject({ name: to.meta?.redirect || ROUTE_NAMES.AUTH });
});
export default (store) => (to, from, next) => {
    console.log(to, from);
    if (!(to.name && to.name in ROUTE_NAMES)) {
        return;
    }
    return new Promise((resolve, reject) => {
        const user = store.getters['users/getUser'];
        if (user) {
            return resolve(main(to, from, next, store));
        }
        else if (localStorage.getItem('userToken')) {
            return requestAuthCheck({
                username: localStorage.getItem('userUsername') || '',
                token: localStorage.getItem('userToken') || ''
            }).then((r) => {
                store.commit('users/setUser', r.data.user);
                ApiClient.setAutoAuthHeaders();
                return resolve(main(to, from, next, store));
            }).catch(() => {
                ApiClient.cleanHeaders();
                return reject({ name: to.meta?.redirect || ROUTE_NAMES.AUTH });
            });
        }
        else {
            return resolve(main(to, from, next, store));
        }
    })
        .then((route) => {
        return next();
    })
        .catch((route) => {
        return next(route);
    })
        .finally(() => {
        document.title = to.meta?.title ? store.getters['app/getTranslate'](to.meta?.title) : 'RCMS';
    });
};
