<template>
  <div
    class="form-control"
    :class="'form-control--' + size"
  >
    <div
      v-if="label"
      class="form-label"
      :class="[ labelUp ? 'active' : '', status ]"
      @click="() => setForceLabel()"
    >
      {{ label }}
    </div>

    <div class="form-input-wrapper">
      <DatePicker
        v-model="date"
        type="date"
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        @change="v => emitVal(v)"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: "InputDate",
  components: {
    DatePicker
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    status: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    date: {
      get () {
        return this.value
      },
      set (v) {
        this.emitVal(v)
      }
    },
    getIconStatus () {
      if (this.status === 'success') {
        return 'check'
      } else if (this.status === 'error') {
        return 'exclamation-circle'
      } else {
        return false;
      }
    },
    labelUp () {
      return (this.value && this.value.length > 0) || this.forceLabel;
    },
    options () {
      return {
        placeholder: 'Введите'
      }
    }
  },
  methods: {
    emitVal (v) {
      this.$emit('change', v);
    },
    setForceLabel (v = true) {
      if (v) {
        this.$refs.input.focus();
      }
      this.forceLabel = v;
    }
  }
}
</script>

<style scoped lang="less">
.form-control {
  @padding-form: 12px 14px 10px;
  position: relative;

  .form-label {
    position: absolute;
    padding: 0 14px;
    font-family: @ffOne;
    font-size: 16px;
    color: #C2C2C2;
    transition: 0.2s ease-in-out;
    top: 8px;
    z-index: 1;
    user-select: none;
    cursor: text;

    &.active {
      font-size: 14px;
      top: -20px;
      color: var(--cBase3);
    }

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  .form-input {
    width: 100%;
    color: var(--cBase3);
    padding: 0 12px;
    line-height: 36px;
    box-sizing: border-box;
    transition: 0.2s;
    font-family: @ffOne;
    font-size: 14px;
    background: white;
    border-radius: 2px;
    outline: none;
    border: @sizeBorderDefault solid var(--cBase2);
    border-bottom: @sizeBorderDefault solid var(--cBase2);

    &:focus {
      border: @sizeBorderDefault solid @cBaseThree;
    }

    &-wrapper {
      position: relative;
      margin-top: 20px;
    }

    &::placeholder {
      color: #bfbfbf;
    }

    &.success {
      border-bottom: @sizeBorderDefault solid #75dc75;

      &:focus {
        border: @sizeBorderDefault solid #75dc75;
        border-bottom: @sizeBorderDefault solid #75dc75;
      }
    }

    &.error {
      border-bottom: @sizeBorderDefault solid #cc524a;

      &:focus {
        border: @sizeBorderDefault solid #cc524a;
        border-bottom: @sizeBorderDefault solid #cc524a;
      }
    }

    &[disabled] {
      border-bottom: @sizeBorderDefault solid #777;
    }
  }

  & .icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: @padding-form;

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  & .dialog {
    font-family: @ffOne;
    font-size: 14px;
    padding: 5px 10px;
    color: #cc524a;
    font-weight: bold;
  }

  &--mini {
    .form-input {
      padding: 0 14px;
      line-height: 30px;
      border-radius: 4px;
    }

    .form-label {
      top: 24px;

      &.active {
        top: -2px;
        color: var(--cBase3);
      }
    }
  }
}
</style>

<style lang="less">
.mx-datepicker {
  width: 100%;
}

.mx-input {
  border-radius: 2px;
  outline: none;
  border: @sizeBorderDefault solid var(--cBase2);
  box-shadow: none;

  &::placeholder {
    font-family: @ffOne;
  }

  &:hover {
    border: @sizeBorderDefault solid var(--cBase2);
  }

  &:focus {
    border: @sizeBorderDefault solid var(--cBase3);
  }
}
</style>
