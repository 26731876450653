<template>
  <ModalRCMS>
    <span @click="() => $emit('close')">Настройка поля</span>
  </ModalRCMS>
</template>

<script>
import ModalRCMS from "@/components/parts/Modal";

export default {
  name: "FieldConfig",
  components: {
    ModalRCMS
  },
  props: {
    params: Object
  },
  mounted() {
    console.log(this.params)
  }
}
</script>

<style scoped>

</style>
