export const TYPE_FIELD = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  RELATION: 'relation',
  MEDIA: 'media',
}

export const listTypeField = [
  TYPE_FIELD.TEXT,
  TYPE_FIELD.TEXTAREA,
  TYPE_FIELD.NUMBER,
  'enumerate',
  'date',
  'boolean',
  TYPE_FIELD.RELATION,
  'media'
]

export const namedListTypeField = {
  [TYPE_FIELD.TEXT]: {
    color: 'blue',
    icon: 'text',
    title: 'Текст'
  },
  [TYPE_FIELD.TEXTAREA]: {
    color: 'green',
    icon: 'subject',
    title: 'Большой текст'
  },
  [TYPE_FIELD.NUMBER]: {
    color: 'orange',
    icon: 'number',
    title: 'Число'
  },
  enumerate: {
    color: 'violet',
    icon: 'enumerate',
    title: 'Перечисляемое свойство'
  },
  date: {
    color: 'red',
    icon: 'date',
    title: 'Дата и время'
  },
  boolean: {
    color: 'aqua',
    icon: 'boolean',
    title: 'Логическое значение'
  },
  [TYPE_FIELD.RELATION]: {
    color: 'bluegrey',
    icon: 'relation',
    title: 'Связь'
  },
  media: {
    color: 'pink',
    icon: 'media',
    title: 'Медиа'
  }
}

export const namedListColorMap = {
  green: '#00AB40',
  blue: '#006DAB',
  orange: '#E48900',
  violet: '#9b40ec',
  brown: '#795548',
  aqua: '#70d6d6',
  pink: '#f451bd',
  red: '#fb4747',
  bluegrey: '#486e82',
}
