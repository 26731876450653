<template>
  <div v-if="getEntityData" class="form">
    <div v-for="field in fields" :key="field.id" class="form__item">
      <FormControl
        :value="field.type === 'media' ? getFiles[getValues[field.code]] : getValues[field.code]"
        :label="field.title"
        :type="field.type"
        :config="field.type === 'relation' ? buildConfig(field) : { multi: getMultiMode }"
        @change="(value, _config) => change({ field, value }, _config)"
      />
    </div>
    <div class="form__item form__item--full">
      <CustomBtn v-if="!getMultiMode" @click="() => saveHandler()" style="margin-right: 10px">Сохранить</CustomBtn>
      <CustomBtn v-if="!getMultiMode && this.mode !== 'settings'" @click="() => saveHandler(true)">Сохранить как новый</CustomBtn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomBtn from "../ui/Btn";
import FormControl from "../ui/FormControl";
import { namedListTypeField } from "@/const";
import { requestNodes } from "@/services/api/requests";
import { convertToSlug } from "@/services/common";

export default {
  name: 'NodeForm',
  components: {
    FormControl,
    CustomBtn
  },
  props: {
    mode: {
      type: String,
      default: 'create'
    }
  },
  computed: {
    ...mapGetters('forms/node', [
      'getValues',
      'getForm',
      'getFiles',
      'getMultiMode'
    ]),
    ...mapGetters('entities', [
      'getEntityByCode'
    ]),
    getEntityData() {
      return this.getEntityByCode(this.getForm.entity)
    },
    fields () {
      return this.getEntityData && this.getEntityData.fields || {}
    },
    dataTypes() {
      return namedListTypeField
    }
  },
  destroyed() {
    this.clear()
  },
  methods: {
    ...mapMutations('forms/node', [
      'clear',
      'setField'
    ]),
    ...mapActions('forms/node', [
      'save'
    ]),
    saveHandler (isNew = false) {
      return this.save({ entity: this.getEntityData.code, mode: isNew && 'isNew' || this.mode })
        .then(() => {
          if (this.getMultiMode) {
            return
          }
          this.$router.push({ name: this.$routeNames.ENTITY, params: { entity: this.getEntityData.code } })
        })
    },
    change (v, _config = null) {
      this.setField(v)

      if (this.getMultiMode) {
        this.saveHandler()
          .then(() => {
            _config.callback()
          })
      }

      const { field, value } = v
      Object.values(this.fields).forEach((i) => {
        // обновление slug свойства
        if (i.config && i.config.slug === field.hash) {
          this.setField({ field: i, value: convertToSlug(value) })
        }
        // обновление имени файла
        if (i.config && i.config.fileName === field.hash) {
          // console.log(value)
          this.setField({ field: i, value: value.name })
        }
      })
    },
    getTypeField (name) {
      return this.dataTypes[name] || {}
    },
    buildConfig (field) {
      return {
        endpointOptions: (params) => requestNodes({ hash: field.config.entityHash, ...params }),
        responseMapper: (response) => response.data.nodes.map((item) => ({ value: item.hash, label: item.fields[response.data.entity.config.mainField] }))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  gap: 10px;

  &__item {

    //&--full {
    //  grid-column-start: 1;
    //  grid-column-end: 3;
    //}
  }

  &__fields {
    padding: 16px 16px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 14px;
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--cBase1);
    padding: 10px 20px;
    border-radius: 4px;
    gap: 24px;

    &__nav {
      display: flex;
      justify-content: end;
      flex: 1;
      opacity: 0;
      align-items: center;
      gap: 6px;
      transition: 0.2s;
    }

    &--removed {
      opacity: 0.5;
    }

    &:hover &__nav {
      opacity: 1;
    }
  }
}
</style>
